import React, { useEffect } from "react"
import { appWithTranslation } from "next-i18next"
import NextNprogress from "nextjs-progressbar"
import "../styles/globals.css"
import { useRouter } from "next/router"

const App = ({ Component, pageProps }) => {
  return (
    <>
      <NextNprogress
        color="#29D"
        startPosition={0.3}
        stopDelayMs={200}
        height={3}
        showOnShallow={true}
      />
      <Component {...pageProps} />
    </>
  )
}

export default appWithTranslation(App)
